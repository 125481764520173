import React from "react"
import "./loanOfficerBlock.scss"
import Button from "../Button/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
function LoanOfficerBlock({ props }) {
  let userInfo = props
  return (
    <div className="loan-officer">
      <div className="loan-officer_top-content-bg">
        <div className="top-content">
          <div className="avatar">
            <GatsbyImage
              image={getImage(userInfo?.featuredImage?.node?.localFile)}
              alt="Avatar"
            />
          </div>
          <div className="infor">
            <p>{userInfo?.officers?.position}</p>
            <p>NMLS: {userInfo?.officers?.nmls}</p>
            {userInfo?.officers?.hixonLendingNmls && (
              <p>Hixon Mortgage NMLS: {userInfo?.officers?.hixonLendingNmls}</p>
            )}
            {userInfo?.officers?.licensedIn && (
              <p>Licensed In: {userInfo?.officers?.licensedIn}</p>
            )}
            <a
              className="phone"
              href={`tel:${userInfo?.officers?.phone?.title}`}
            >
              <FontAwesomeIcon icon={faPhone} />
              {userInfo?.officers?.phone?.title}
            </a>
            {userInfo?.officers?.social && (
              <ul className="social">
                {userInfo?.officers?.social?.map(node => {
                  return (
                    <div>
                      {node?.link?.url && (
                        <li key={node?.link?.url}>
                          <a href={node?.link?.url} tagret="_blank">
                            <img src={node?.icon?.sourceUrl} alt="Social icon" />
                          </a>
                        </li>
                      )}
                    </div>
                  )
                })}
              </ul>
            )}
            <div className="button-wrapper">
              {userInfo?.officers?.loanSingleButtons?.map(({ link }) => {
                return (
                  <Button
                    key={link?.title}
                    button={{
                      text: link?.title,
                      url: link?.url,
                      type: "black",
                      target: "_blank"
                    }}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-content_bg-wrapper">
        <div className="bottom-content">
          <div className="inner-text">
            <h3>About {userInfo?.title}</h3>
            <div dangerouslySetInnerHTML={{ __html: userInfo?.content }} />
          </div>
          <div className="big-text">{userInfo?.officers?.slogan}</div>
        </div>
      </div>
    </div>
  )
}

export default LoanOfficerBlock
