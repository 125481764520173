import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layouts/layout"
import PageTitle from "../components/PageTitle/pageTitle"
import LoanOfficerBlock from "../components/LoanOfficerBlock/LoanOfficerBlock"
import * as myModule from "../components/dynamic"
import Seo from 'gatsby-plugin-wpgraphql-seo'

function loanOfficerSingle({ data }) {
  const wpData = data?.wpLoanOfficer?.sections?.blocks
  const seo = data?.wpLoanOfficer?.seo
  const pageTitle = {
    title: data?.wpLoanOfficer?.title,
    subtitle: "Loan Officer",
    box: { title: "true" },
    bgLoanOfficer: true,
  }

  return (
    <Layout>
      <Seo post={data?.wpLoanOfficer} />
      <PageTitle {...pageTitle} />
      <LoanOfficerBlock props={data?.wpLoanOfficer} />
      {wpData?.map(row => {
        // assign component math with name
        const Component = myModule[row.fieldGroupName.split("_").slice(-1)[0]]
        return <Component key={row.fieldGroupName} {...row} />
      })}
    </Layout>
  )
}

export default loanOfficerSingle

export const query = graphql`
  query($slug: String!) {
    wpLoanOfficer(slug: { eq: $slug }) {
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      title
      content
      slug
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 528
                height: 528
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
      officers {
        position
        nmls
        hixonLendingNmls
        licensedIn
        location
        phone {
          title
        }
        email {
          title
        }
        buttons {
          link {
            target
            title
            url
          }
        }
        loanSingleButtons {
          link {
            target
            title
            url
          }
        }
        social {
          link {
            title
            target
            url
          }
          icon {
            sourceUrl
          }
        }
        slogan
      }
      sections {
        blocks {
          ... on WpLoanOfficer_Sections_Blocks_ImageAside {
            fieldGroupName
            type
            title
            subtitle
            logo {
              sourceUrl
            }
            imagePosition
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            content
            button {
              url
              title
              target
            }
          }
          ... on WpLoanOfficer_Sections_Blocks_Testimonials {
            fieldGroupName
            testimonials {
              ... on WpTestimonial {
                content
                id
              }
            }
            testimonialsColumn2 {
              ... on WpTestimonial {
                content
                id
              }
            }
          }
          ... on WpLoanOfficer_Sections_Blocks_DoubleTextSlider {
            fieldGroupName
            column1 {
              topTitle
              bottomTitle
            }
            column2 {
              sliderContent {
                title
                content
                button {
                  title
                  url
                  target
                }
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
